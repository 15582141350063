import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { DataService } from '@services/data.service';
import { APIResponse } from '@models/api-response-data';
import { ApiCallService } from 'adstracker-lib';
import { API_ENDPOINTS } from '@utils/api-endpoints';
import { LOCAL_STORAGE_KEY, StorageService } from 'adstracker-lib';
import dayjs from 'dayjs';
import { IDateSelected } from '@models/common';

export const AppSettingsResolver: ResolveFn<unknown> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  dataService = inject(DataService),
  apiService = inject(ApiCallService),
  localStorageService = inject(StorageService)
) => {
  const appSettings = dataService.getAppSettings();
  let dateSelected: IDateSelected = {
    from: dayjs().startOf('day').toISOString(),
    to: dayjs().endOf('day').toISOString(),
    timezone: 'UTC',
    label: 'Today'
  }

  if (localStorageService.getItem(LOCAL_STORAGE_KEY.DATE_SELECTED)) {
    dateSelected = JSON.parse(localStorageService.getItem(LOCAL_STORAGE_KEY.DATE_SELECTED) || '')
  }

  if (!appSettings) {
    return apiService.parseApiCall(API_ENDPOINTS.SETTINGS.GET_SETTINGS.URI, API_ENDPOINTS.SETTINGS.GET_SETTINGS.METHOD, {})
      .pipe(map((response: APIResponse) => {
        if (response.data) {
          dataService.setAppSettings(response.data.settings);
          dateSelected.timezone = response.data.settings.default_time_zone;
          localStorageService.setItem(LOCAL_STORAGE_KEY.DATE_SELECTED, JSON.stringify(dateSelected))
          return response.data.settings;
        }
        return null;
      }));
  }
  dateSelected.timezone = appSettings.default_time_zone
  localStorageService.setItem(LOCAL_STORAGE_KEY.DATE_SELECTED, JSON.stringify(dateSelected))
  return appSettings;
}
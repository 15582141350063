import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ThemeService } from '@services/theme.service';
import { registerLicense } from '@syncfusion/ej2-base';
import { environment } from 'src/environments/environment';
registerLicense(environment.syncfusion_key);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html'
})
export class AppComponent {
  themeService: ThemeService = inject(ThemeService);
}

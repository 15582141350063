import { inject } from '@angular/core';
import {
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
    ResolveFn,
} from '@angular/router';
import { APIResponse } from '@models/api-response-data';
import { DataService } from '@services/data.service';
import { API_ENDPOINTS } from '@utils/api-endpoints';
import { ApiCallService } from 'adstracker-lib';
import { map } from 'rxjs/operators';

export const UserResolver: ResolveFn<unknown> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    dataService = inject(DataService),
    apiService = inject(ApiCallService)
) => {
    const userDetails = dataService.getUserDetails();
    if (!userDetails) {
        return apiService.parseApiCall(API_ENDPOINTS.USER.GET_PROFILE_DETAILS.URI, API_ENDPOINTS.USER.GET_PROFILE_DETAILS.METHOD, {})
            .pipe(map((response: APIResponse) => {
                if (response.data) {
                    dataService.setUserDetails(response.data.user_details);
                    return response.data.user_details;
                }
                return null;
            }));
    }
    return userDetails;
}

import { Injectable } from '@angular/core';
import {
  Router
} from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {
  constructor(
    public auth: AuthenticationService,
    public router: Router,
  ) { }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      // to do -> logout action
      return false;
    }
    return true;
  }

  canActivateChild(): boolean {
    if (!this.auth.isAuthenticated()) {
      // to do -> logout action
      return false;
    }
    return true;
  }
}

import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { AuthenticationService } from '@services/authentication.service';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { CommonModule } from '@angular/common';
import { RouteInfo } from '@models/route-info-data';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatBadgeModule,
    RouterLink,
    MatSidenavModule,
    MatListModule,
    CommonModule,
    MatMenuModule],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  subMenuList = [false]
  showSubmenu: boolean = false;
  menuItems: RouteInfo[] = [];
  icon = 'store'
  constructor(
    public auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    const userType = this.auth.getUserType();
    this.menuItems = ROUTES.filter((menuItem) => menuItem.role === userType);
  }

  logOut() {
    this.auth.doLogout();
  }
}

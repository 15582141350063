import { Routes } from '@angular/router';

export const ROOT_DASHBOARD_ROUTES: Routes = [
    {
        path: 'settings',
        loadChildren: () =>
            import('../../modules/panel/settings/settings.module').then((m) => m.SettingsModule),
    },
    {
        path: 'affiliate-networks',
        loadChildren: () =>
            import('../../modules/panel/affiliate-networks/affiliate-networks.module').then((m) => m.AffiliateNetworksModule),
    },
    {
        path: 'traffic-sources',
        loadChildren: () =>
            import('../../modules/panel/traffic-sources/traffic-sources.module').then((m) => m.TrafficSourcesModule),
    },
    {
        path: 'landings',
        loadChildren: () =>
            import('../../modules/panel/landings/landings.module').then((m) => m.LandingsModule),
    },
    {
        path: 'offers',
        loadChildren: () =>
            import('../../modules/panel/offers/offers.module').then((m) => m.OffersModule),
    },
    {
        path: 'campaigns',
        loadChildren: () =>
            import('../../modules/panel/campaigns/campaigns.module').then((m) => m.CampaignsModule),
    },
    {
        path: 'flows',
        loadChildren: () =>
            import('../../modules/panel/flows/flows.module').then((m) => m.FlowsModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('../../modules/panel/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'reports/:report_type',
        loadChildren: () =>
            import('../../modules/panel/reports/reports.module').then((m) => m.ReportsModule),
    },
    {
        path: 'notifications',
        loadChildren: () =>
            import('../../modules/panel/notifications/notifications.module').then((m) => m.NotificationsModule),
    },
]

export const SUPER_ROOT_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () =>
            import('../../modules/cpanel/dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
        path: 'domains',
        loadChildren: () =>
            import('../../modules/cpanel/domians/domians.module').then((m) => m.DomiansModule),
    },
    {
        path: 'plans',
        loadChildren: () =>
            import('../../modules/cpanel/plans/plans.module').then((m) => m.PlansModule),
    },
    {
        path: 'preferences',
        loadChildren: () =>
            import('../../modules/cpanel/preferences/preferences.module').then((m) => m.PreferencesModule),
    },
    {
        path: 'roles',
        loadChildren: () =>
            import('../../modules/cpanel/roles/roles.module').then((m) => m.RolesModule),
    },
    {
        path: 'settings',
        loadChildren: () =>
            import('../../modules/cpanel/settings/settings.module').then((m) => m.SettingsModule),
    },
    {
        path: 'users',
        loadChildren: () =>
            import('../../modules/cpanel/users/users.module').then((m) => m.UsersModule),
    },
    {
        path: 'integration',
        loadChildren: () =>
            import('../../modules/cpanel/integration/integration.module').then((m) => m.IntegrationModule),
    }
]

import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NavBarComponent } from '@components/nav-bar/nav-bar.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-layout',
  standalone: true,
  imports: [
    NavBarComponent,
    SidebarComponent,
    RouterModule,
    RouterOutlet,
    MatSidenavModule],
  templateUrl: './dashboard-layout.component.html'
})
export class DashboardLayoutComponent {
  year = new Date().getFullYear();
  appVersion = environment.app_version.version;
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService, LOCAL_STORAGE_KEY, StorageService } from 'adstracker-lib';
import { API_ENDPOINTS } from '@utils/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {

  private isRefreshing = false;

  constructor(
    private authService: AuthenticationService,
    private toaster: ToastrService,
    private apiService: ApiCallService,
    private localStorageService: StorageService
  ) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          if ([401, 403].includes(error.status)) {
            // auto logout if 401 or 403 response returned from api
            // this.authService.doLogout();
            console.log('request', request.url)
            return this.handle401Error(request, next);
          }

        }
        // If you want to return a new response:
        this.toaster.error(error.error.message)
        return of(new HttpResponse({ body: { success: false, message: error.error.message } }));

        // If you want to return the error on the upper level:
        // return throwError(error);

        // or just return nothing:
        // return EMPTY;
      })
    );
  }

  private handle401Error(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.apiService.parseApiCall(API_ENDPOINTS.AUTH.REFRESH_TOKEN.URL, API_ENDPOINTS.AUTH.REFRESH_TOKEN.METHOD, {
        refresh_token: this.localStorageService.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN)
      }).pipe(
        switchMap((response) => {
          if (response.success) {
            this.localStorageService.setItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN, response.data.token);
            this.localStorageService.setItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN, response.data.refresh_token);
            this.isRefreshing = false;
            window.location.reload();
            return next.handle(this.addToken(request, response.data.token))
          } else {
            this.authService.doLogout();
            return of(new HttpResponse({ body: { success: false, message: 'Invalid login session' } }));
          }
        }),
        catchError((error) => {
          this.isRefreshing = false;
          if ([401, 403].includes(error.status)) {
            this.authService.doLogout();
          }
          return throwError(() => error);
        })
      );
    } else {
      return of(new HttpResponse({ body: { success: false } }));
    }
  }

  private addToken(request: HttpRequest<unknown>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

import { Routes } from '@angular/router';
import { AuthGuardService } from '@services/auth-guard.service';
import { GuestLayoutComponent } from './modules/layouts/guest-layout/guest-layout.component';
import { AUTH_ROUTES } from '@routes/content-layout.routes';
import { ROOT_DASHBOARD_ROUTES, SUPER_ROOT_ROUTES } from '@routes/dashboard-layout.routes';
import { DashboardLayoutComponent } from './modules/layouts/dashboard-layout/dashboard-layout.component';
import { RoleGuardService } from '@services/role-guard.service';
import { AppSettingsResolver } from '@resolvers/app-settings.resolver';
import { UserResolver } from '@resolvers/user.resolver';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        component: GuestLayoutComponent,
        data: { title: 'Auth Views' },
        children: AUTH_ROUTES
    },
    {
        path: 'panel',
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        component: DashboardLayoutComponent,
        data: { title: 'Dashboard', expectedRole: 'admin' },
        runGuardsAndResolvers: 'always',
        children: ROOT_DASHBOARD_ROUTES,
        resolve: { userDetails: UserResolver, settings: AppSettingsResolver }
    },
    {
        path: 'cpanel',
        canActivate: [RoleGuardService],
        canActivateChild: [RoleGuardService],
        component: DashboardLayoutComponent,
        data: { title: 'Dashboard', expectedRole: 'root' },
        runGuardsAndResolvers: 'always',
        children: SUPER_ROOT_ROUTES,
        resolve: { userDetails: UserResolver }
    },

];

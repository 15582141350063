<mat-toolbar [color]="navBackground">
    AdsTracker
    <span style="flex: 1 1 auto;"></span>
    <div>
        <button mat-icon-button matTooltip="Notifications" (click)="loadNotificationPage()">
            <mat-icon [matBadge]="notificationsCount" [matBadgeHidden]="notificationsCount === 0">notifications
            </mat-icon>
        </button>

        <mat-menu #accountMenu [overlapTrigger]="false" yPosition="below">
            <button mat-menu-item [routerLink]="[userType === 'root'? 'settings/profile' : '/panel/settings/general']">
                <mat-icon>person</mat-icon><span>My Profile</span>
            </button>
            <mat-divider></mat-divider>

            <button mat-menu-item>
                @if (themeService.appTheme() === 'dark'){
                <mat-icon>bedtime</mat-icon>
                } @else {
                <mat-icon>brightness_5</mat-icon>
                }
                <span class="me-4">Dark Mode</span><mat-slide-toggle [checked]="themeService.appTheme() === 'dark'"
                    (click)="toggleTheme()"></mat-slide-toggle>
            </button>

            <mat-divider></mat-divider>
            <button mat-menu-item (click)="doLogout()">
                <mat-icon>exit_to_app</mat-icon>Logout
            </button>
        </mat-menu>
    </div>
    <div class="user-box">
        <a href="javascript:;" id="dropdownBasic4" data-toggle="dropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false" class="d-flex align-items-center nav-link " matTooltip="My Account"
            [matMenuTriggerFor]="accountMenu">
            <img class="user-img" [src]="profileImage" style="max-width: 130px; max-height: 60px" alt="" />
            <div class="user-info">
                <p class="user-name mb-0">{{newUserName}}</p>
                <!-- <p class="designattion mb-0">{{'name'}}</p> -->
            </div>
        </a>

    </div>
</mat-toolbar>
<mat-nav-list>
    <ng-container *ngFor="let menuItem of menuItems; let i = index">
        <ng-container *ngIf="!menuItem.submenu.length">
            <div [routerLink]=[menuItem.path]>
                <div routerLinkActive="menu-active">
                    <mat-list-item class="parent mat-list-item">
                        <mat-icon>{{menuItem.icon}}</mat-icon> <span
                            class="menu-title full-width">{{menuItem.title}}</span>
                    </mat-list-item>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="menuItem.submenu.length">
            <mat-list-item class="parent mat-list-item" (click)="subMenuList[i] = !subMenuList[i]">
                <mat-icon>{{menuItem.icon}}</mat-icon> <span class="menu-title full-width">{{menuItem.title}}</span>
                <mat-icon class="menu-button" [ngClass]="{'rotated' : subMenuList[i]}">expand_more</mat-icon>
            </mat-list-item>
            <div class="submenu" [ngClass]="{'expanded' : subMenuList[i]}" *ngIf="subMenuList[i]">
                <mat-list-item *ngFor="let submenu of menuItem.submenu" class="parent mat-list-item"
                    [routerLink]=[submenu.path]>
                    <span class="menu-title full-width">{{submenu.title}}</span>
                </mat-list-item>
            </div>
        </ng-container>
    </ng-container>
    <ng-container>
        <mat-list-item class="parent mat-list-item" (click)="logOut()">
            <mat-icon>logout</mat-icon> <span class="menu-title full-width">Logout</span>
        </mat-list-item>
    </ng-container>
</mat-nav-list>
import { Component, OnInit, inject } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, Router } from '@angular/router';
import { AppEventType } from '@models/event-type';
import { AuthenticationService } from '@services/authentication.service';
import { EventQueueService } from '@services/event-queue.service';
import { ThemeService } from '@services/theme.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { API_ENDPOINTS } from '@utils/api-endpoints';
import { FormGroup } from '@angular/forms';
import { APIResponse, IUsers } from '@models/api-response-data';
import { ApiCallService } from 'adstracker-lib';
import { DataService } from '@services/data.service';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    MatToolbarModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatBadgeModule,
    RouterLink,
    MatMenuModule,
    MatSlideToggleModule],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  notificationsCount: number = 0;
  newUserName: string = '';
  userType!: string | null;
  navBackground: string = ''
  portalName: string = '';
  userName: string = '';
  portalType = 'Admin Panel';
  userDetails!: IUsers | null;
  dataForm!: FormGroup;
  themeService: ThemeService = inject(ThemeService);
  eventQueue: EventQueueService = inject(EventQueueService);
  auth: AuthenticationService = inject(AuthenticationService);
  apiService: ApiCallService = inject(ApiCallService);
  dataService: DataService = inject(DataService);
  constructor(
    private router: Router,
  ) { }
  navbarClass: { [key: string]: string; } = {
    'root': 'accent'
  }
  profileImage: string = '';

  ngOnInit(): void {
    this.eventQueue.on(AppEventType.UserImageReload).subscribe(event => this.updateUserProfilePic(event));
    this.eventQueue.on(AppEventType.UserNameReload).subscribe(() => this.getUserProfileName());
    this.eventQueue.on(AppEventType.NotificationCountReload).subscribe(() => this.getNotificationCount());
    this.userDetails = this.dataService.getUserDetails();
    if (this.userDetails && this.userDetails.profile_image) {
      this.profileImage = API_ENDPOINTS.MEDIA_URL + this.userDetails.profile_image;
    }
    this.userType = this.auth.getUserType();
    if (this.userType) {
      this.navBackground = this.navbarClass[this.userType]
    }
    this.getNotificationCount();
    this.getUserProfileName();

  }
  getUserProfileName() {
    this.apiService.parseApiCall(API_ENDPOINTS.USER.GET_PROFILE_DETAILS.URI, API_ENDPOINTS.USER.GET_PROFILE_DETAILS.METHOD, {
    }).subscribe((res: APIResponse) => {
      if (res.success) {
        this.newUserName = res.data.user_details.first_name + " " + res.data.user_details.last_name;
      }
    });
  }

  updateUserProfilePic(data: { payload: { profile_image: string } }) {
    this.profileImage = API_ENDPOINTS.MEDIA_URL + data.payload.profile_image;
  }

  doLogout() {
    this.auth.doLogout()
  }

  getNotificationCount() {
    this.apiService.parseApiCall(API_ENDPOINTS.NOTIFICATIONS.GET_COUNT.URI, API_ENDPOINTS.NOTIFICATIONS.GET_COUNT.METHOD, {
    }).subscribe((res: APIResponse) => {
      if (res.success) {
        this.notificationsCount = res.data.notifications_count;
      }
    });
  }

  loadNotificationPage() {
    this.apiService.parseApiCall(API_ENDPOINTS.USER.GET_PROFILE_DETAILS.URI, API_ENDPOINTS.USER.GET_PROFILE_DETAILS.METHOD, {
    }).subscribe((res: APIResponse) => {
      if (res.success) {
        if (res.data.user_details.role.name === 'admin') {
          this.router.navigate(['/panel/notifications'])
        } else {
          this.router.navigate(['/cpanel/notifications'])
        }
      }
    });
  }

  toggleTheme() {
    this.themeService.updateTheme();
    this.router.navigate([`/panel/notifications`])
  }
}

import { RouteInfo } from '@models/route-info-data';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/panel/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/panel/reports/campaigns',
    title: 'Reports',
    icon: 'bar_chart',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/panel/campaigns',
    title: 'Campaigns',
    icon: 'track_changes',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/panel/offers',
    title: 'Offers',
    icon: 'monetization_on',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/panel/landings',
    title: 'Landings',
    icon: 'call_to_action',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/panel/flows',
    title: 'Flows',
    icon: 'device_hub',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/panel/traffic-sources',
    title: 'Traffic Sources',
    icon: 'language',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/panel/affiliate-networks',
    title: 'Affiliate Networks',
    icon: 'group',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Settings',
    icon: 'settings',
    class: '',
    badge: '',
    role: 'admin',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/panel/settings/general',
        title: 'General',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/security',
        title: 'Security',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/domains',
        title: 'Domains',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/tracking-url',
        title: 'Tracking Urls',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/upload-conversions',
        title: 'Upload Conversions',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/bot-filters',
        title: 'Bot Filters',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/subscriptions',
        title: 'Subscription',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/referrral-program',
        title: 'Referral Program',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/multi-user-access',
        title: 'Multi-User Access',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/custom-conversions',
        title: 'Custom Conversions',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/plan-list',
        title: 'Plan Description',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/settings/integration',
        title: 'Integration',
        icon: '',
        class: '',
        badge: '',
        role: 'admin',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
    ],
  },

  //Superadmin panel routes
  {
    path: '/cpanel/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/cpanel/users/list',
    title: 'Accounts',
    icon: 'person',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Domains',
    icon: 'web',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/cpanel/domains/tracking-domains',
        title: 'Tracking Domain',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/domains/redirect-domains',
        title: 'Redirect Domains',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '/cpanel/plans/list',
    title: 'Plans',
    icon: 'business_center',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '/cpanel/roles/list',
    title: 'Roles',
    icon: 'security',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Preferences',
    icon: 'settings',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/cpanel/preferences/countires',
        title: 'Country List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/currency',
        title: 'Currency List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/city',
        title: 'City List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/state',
        title: 'State List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/timezone',
        title: 'Timezone List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/languages',
        title: 'Languages List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/brands',
        title: 'Brands List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/browsers',
        title: 'Browsers List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/connections',
        title: 'Connection List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/oses',
        title: 'Oses List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/cpanel/preferences/devices',
        title: 'Device List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '/cpanel/integration/list',
    title: 'Integration',
    icon: 'merge_type',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
    ],
  },

];

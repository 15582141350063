import { Injectable, signal } from '@angular/core';
import { LOCAL_STORAGE_KEY, StorageService } from 'adstracker-lib';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  body: HTMLElement = document.body as HTMLElement
  themeMode = this.storageService.getItem(LOCAL_STORAGE_KEY.THEME_MODE) || ''
  appTheme = signal<string>(this.themeMode || "light");
  constructor(private storageService: StorageService) {
    this.body.setAttribute('data-bs-theme', this.themeMode)
  }

  setTheme(theme: string) {
    this.appTheme.set(theme);
  }

  updateTheme() {
    this.appTheme.update((value) => (value === "dark" ? "light" : "dark"));
    this.storageService.setItem(LOCAL_STORAGE_KEY.THEME_MODE, this.appTheme())
    this.body.setAttribute('data-bs-theme', this.appTheme())
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { StorageService } from 'adstracker-lib';
import { USER_TYPES } from '@utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public router: Router,
    public authService: AuthenticationService,
    public localStorageService: StorageService,
  ) { }

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      const userType: string | null = this.authService.getUserType();
      switch (userType) {
        case USER_TYPES.ADMIN || USER_TYPES.USER:
          this.router.navigate([`/panel/dashboard`]);
          break;
        case USER_TYPES.ROOT:
          this.router.navigate([`/cpanel/dashboard`]);
          break;
        default:
          this.authService.doLogout()
          break;
      }
      return false;
    }
    return true;
  }

  canActivateChild(): boolean {
    if (this.authService.isAuthenticated()) {
      const userType: string | null = this.authService.getUserType();
      switch (userType) {
        case USER_TYPES.ADMIN || USER_TYPES.USER:
          this.router.navigate([`/panel/dashboard`]);
          break;
        case USER_TYPES.ROOT:
          this.router.navigate([`/cpanel/dashboard`]);
          break;
        default:
          this.authService.doLogout()
          break;
      }
      return false;
    }
    return true;
  }
}

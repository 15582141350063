<app-nav-bar></app-nav-bar>
<mat-sidenav-container style="height: calc(100% - 64px); margin: 0;">
    <mat-sidenav #sidenav mode="side" opened="true" [class.mat-elevation-z4]="true" style="width: 240px">
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class=" container-fluid mat-body-1 mb-5">
            <router-outlet></router-outlet>
        </div>
        <footer class="page-footer">
            <p>
                Copyright © {{year}}. All right reserved. Powered by <a target="_blank"
                    href="https://trackx.ai">AdsTracker</a><b> v{{appVersion}}</b>
            </p>
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>